html,
body,
#root {
  height: 100%;
}

.btn-outline-secondary {
  border-color: #999;
  color: #999;
}

#scollbar {
  overflow-y: scroll;
  margin-right: 0.5rem;
}

#scollbar::-webkit-scrollbar {
  width: 6px;
}

#scollbar::-webkit-scrollbar-track {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

#scollbar::-webkit-scrollbar-thumb {
  width: 6px;
  border-radius: 6px;
  background-color: #666;
}
